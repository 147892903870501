<template>
    <main >
        <div class="header">
            <h1 class="heading">{{$t('objects["Редактировать объект"]')}}</h1>
        </div>
        <v-form class="timeline"
            ref="form"
            v-model="valid"
        >
            <div class="timeline__item timeline__item_hotel">
                <h2 class="timeline__label">{{$t('objects["Данные объекта"]')}}</h2>
                <div class="timeline__value">
                    <div class="field">
                        <div class="control">
                            <v-text-field solo outlined flat
                                :label="$t('objects[\'Название\']')"
                                :rules="nameRules"
                                v-model="object.name"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <v-text-field solo outlined flat
                                :placeholder="$t('objects[\'Адрес\']')"
                                :rules="addressRules"
                                v-model="object.address"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <p class="tip">{{$t('objects["После добавления нового объекта стоимость подписки изменится"]')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="timeline__item timeline__item_tariff">
                <h2 class="timeline__label">{{$t('objects["Тарифный план"]')}}</h2>
                <div class="timeline__value">
                    <div class="field">
                        <ObjectTariff :current="+object.tariff_id" @changeTariff="changeTariff" />
                    </div>
                    <div class="field">
                        <div class="control">
                            <v-btn class="object__submit btn btn_primary" 
                                color="primary" 
                                depressed
                                @click="validate"
                            >
                                {{$t('objects["Сохранить отель"]')}}
                            </v-btn>
                            
                            <PopupEmail ref="PopupEmail" :title="$t('popup.tariff_email', {email: 'me@bs.hr'})" />
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
    </main>
</template>

<script>
import ObjectTariff from '@/components/ObjectTariff.vue'
import PopupEmail from '@/components/Popup/PopupEmail.vue'

import { mapState } from 'vuex';
import store from '@/store'

export default {
    name: 'Objects',
    components: {
        ObjectTariff,
        PopupEmail
    },
    beforeRouteEnter (to, from, next) {

        if(!store.state.objects.list.length){

            store.dispatch('objects/getList')
                .then((data) => {
                    next();
                }).catch(() => {
                next();
            })
        }else{
            next();
        }
        // getPost(to.params.id, (err, post) => {
        //     next(vm => vm.setData(err, post))
        // })
    },
    computed: {
        ...mapState(['objects']),
    },
    data() {
        return {
            valid: false,
            nameRules: [
                v => !!v || this.$t('objects["Название обязательно"]'),
            ],
            addressRules: [
                v => !!v || this.$t('objects["Адрес обязателен"]'),
            ],
            object: {
                name: null,
                address: null,
                tariff_id: 1,
            },
            tariff_id: 1,
        }
    },
    mounted() {
        // let objects = JSON.parse(JSON.stringify(this.objects.list));
        let objects = this.objects.list;
        let object = objects.find(object => object.id === +this.$route.params.id);
        this.object = object;
        this.tariff_id = this.object.tariff_id;
    },
  created() {

  },
  methods: {
        objectCreate(e) {
            e.preventDefault();
        },
        validate() {
            this.$refs.form.validate();
            let data = {
                id: this.object.id,
                name: this.object.name,
                address: this.object.address,
                tariff_id: this.object.tariff_id,
            };
            
            if (this.valid) {
                this.$store.dispatch('objects/edit', data).then(() => {
                    // if (this.object.tariff_id == 1) {
                    //     await this.$router.push({name: 'ObjectsSuccess', params: {lang: this.$route.params.lang}});
                    // } else {
                        this.$refs.PopupEmail.open();
                    // }
                })
            } else {
                this.$nextTick(() => {
                    const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
                    this.$vuetify.goTo(el, {
                        offset: 100
                    });
                    return;
                });
            }
        },
        changeTariff(tariff_id) {
            // this.object.tariff_id = tariff_id;
            this.tariff_id = tariff_id;
        }
    }

};
</script>

<style lang="scss">
.object__submit {
    max-width: 255px;
    width: 100%;
}

</style>